import "../App.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';



export default function About() {
  const theme = useTheme();

  return (
      <Box className="box-2" component="section">
      <Typography
        variant="h1"
        className="name"
        sx={{
          backgroundcolor: "primary",
          backgroundImage: theme.palette.primary.mainGradient,
          backgroundSize: "100%",
          backgroundRepeat: "repeat",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: {
            xs: '10vw',
            sm: '3vw',
            lg: '2vw',
          },
          fontFamily: "Ubuntu",
          fontWeight: "700",
          fontStyle: "normal",
          marginTop: "-4vh",
          marginBottom: "-2vh",
        }}
        >
          DuckyGuy
        </Typography>
        <h2 className="discord">@duckyguy</h2>
        <h3 className="info"> I'm <span style={{
          backgroundImage: theme.palette.primary.mainGradient,
          backgroundSize: "100%",
          backgroundRepeat: "repeat",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          paddingRight: '0.01em',
        }}> Ducky</span>, I have an avid interest in Web Development, Cyber Security and Programming. </h3>
        {/* make the Ducky part above the gradient pls */}
      </Box>
  );
}