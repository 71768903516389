import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function ThemeCard({ colorTheme }) {

  const theme = useTheme();


  return (
    <Card sx={{ color: "#f0f5f5", position: "absolute", width: "35vw", height: "20vh", top: "10vh", left: "20vw", transform: "translate(-50%, -50%)", zIndex: 9999, backgroundColor: theme.palette.background.default }}>
      <CardContent>
        <ButtonGroup variant="contained" aria-label="Theme Selction Buttons">
          <Button onClick={() => colorTheme('Purple')} >Purple</Button>
          <Button onClick={() => colorTheme('Green')} >Green</Button>
          <Button onClick={() => colorTheme('Grey')} >Grey</Button>
        </ButtonGroup>
      </CardContent>
    </Card>
  );
}