import "../App.css";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

export default function Spotify() {
    const ws = new WebSocket('wss://ducky.best/ws');
    const theme = useTheme();

    ws.onmessage = function(event) {
      const data = JSON.parse(event.data);
      if (data.currentSong) {
        document.getElementById('current-song').innerText = data.currentSong;
      }
      if (data.storedImageUrl) {
        const imgElement = document.getElementById('current-image');
        imgElement.src = data.storedImageUrl;
        imgElement.style.display = 'block';
      }
      if (data.currentArtist) {
        document.getElementById('current-artist').innerText = data.currentArtist;
      }
    };
  return (
    <Paper 
        sx={{ 
            backgroundColor: '#d9d9d90d',
            position: 'fixed',
            bottom: 0,
            left: 0,
            margin: '16px',
            borderRadius: '10px',
        }} 
        className="spotify-box"
    >
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          height: '13.5vh',
          overflow: 'hidden'
          }}>
            <img 
            id="current-image" 
            style={{ 
              height: '100%', 
              width: 'auto', 
              borderRadius: '10px', 
              objectFit: 'cover', 
              marginRight: '16px', 
              display: 'none' 
            }} 
            alt="Current" 
            />
            <div className="text-container" style={{ 
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              whiteSpace: 'nowrap' 
              }}>
                <div className="song-container" style={{ 
                  display: 'inline-block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  paddingRight: "1vw" 
                  }}>
                    <p className="current-text" id="current-song" style={{ 
                      fontSize: '1vw',
                      display: 'inline-block',
                      fontWeight: "bold",
                      backgroundImage: theme.palette.primary.mainGradient,
                      backgroundSize: "100%",
                      backgroundRepeat: "repeat",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      }}></p>
                </div>
                <p id="current-artist" style={{ 
                  fontSize: '0.8vw',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  marginTop: '-1vh',
                  paddingRight: "1vw" 
                  }}></p>
            </div>
        </Box>
    </Paper>
  );
}