import { Box } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AppsIcon from '@mui/icons-material/Apps';
import LoginIcon from '@mui/icons-material/Login';
import ThemeCard from './ThemeCard.js'

export default function Menu({ onButtonClick }) {

    const actions = [
      { icon: <AppsIcon />, name: 'Themes', action: () => { onButtonClick() } },
      { icon: <LoginIcon />, name: 'Login', action: () => { console.log('Login clicked') } }
    ]

  return (
    <Box>
        <SpeedDial
        ariaLabel="Menu Button"
        sx={{ position: 'fixed', bottom: 16, right: 16}}
        icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
        </SpeedDial>
    </Box>
  );
}