import { createTheme } from '@mui/material/styles';


// Assuming your theme is defined somewhere above
const GreenTheme = createTheme({
    palette: {
      primary: {
        main: "#79fc95",
        secondary: "#92fda9",
        mainGradient: "linear-gradient(270deg, #0c9ef9, #0491a5, #79fc95)",
      },
      background: {
        default: "#012209",
      },
      // Other theme configurations...
    },
  });

export default GreenTheme;