import { createTheme } from '@mui/material/styles';


// Assuming your theme is defined somewhere above
const BlueTheme = createTheme({
    palette: {
      primary: {
        main: "#30676d",
        secondary: "#38787f",
        mainGradient: "linear-gradient(270deg, #30676d, #66a1bb, #99d2ab)",
      },
      background: {
        default: "#040805",
      },
      // Other theme configurations...
    },
  });

export default BlueTheme;