import "../App.css";
import pfp from '../images/pfp-modified.png';
import Box from '@mui/material/Box';

const Pfp = () => {

    return (
    <Box className="box-1" component="section"> 
        <Box className="pfp-container">
            <img src={pfp} alt="pfp" className="pfp"/>
        </Box>
      </Box>
    );
};

export default Pfp;