import { createTheme } from '@mui/material/styles';

const PurpleTheme = createTheme({
  palette: {
    primary: {
      main: "#50267D",
      secondary: "#8040c5",
      mainGradient: "linear-gradient(270deg, #50267D, #823ACB, #B491D9)",
    },
    background: {
      default: "#0F0815",
    },
    // Other theme configurations...
  },
});

export default PurpleTheme;