import './App.css';
import { useRef, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PurpleTheme from './Themes/Purple_Theme';
import GreyTheme from './Themes/Grey_Theme.js';
import GreenTheme from './Themes/Green_Theme';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Contacts from './components/Contacts.js';
import About from './components/About.js';
import Pfp from './components/Pfp.js';
import Menu from './components/Menu.js';
import ThemeCard from './components/ThemeCard.js';
// import VideoDownloadPage from './components/VideoDownloadPage';
import Spotify from './components/Spotify.js';

export default function App() {
  const [theme, setTheme] = useState(PurpleTheme); // Default theme

  function switchTheme(themeName) {
    switch (themeName) {
      case 'Purple':
        setTheme(PurpleTheme);
        break;
      case 'Green':
        setTheme(GreenTheme);
        break;
      case 'Grey':
        setTheme(GreyTheme);
        break;
      default:
        console.log('Invalid theme name');
    }
  }

  const themeCardRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (themeCardRef.current && !themeCardRef.current.contains(event.target)) {
        setCardVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isCardVisible, setCardVisible] = useState(false);

  function toggleCardVisibility() {
    setCardVisible(!isCardVisible);
  }

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isCardVisible && (
          <div ref={themeCardRef}>
            <ThemeCard colorTheme={switchTheme} />
          </div>
        )}
        <Routes>
          <Route path="/" element={
            <Stack direction="column" className="Container-box">
              <Pfp />
              <About />
              <Divider orientation="horizontal" />
              <Contacts />
              <Menu onButtonClick={toggleCardVisibility} />
              {isLargeScreen && <Spotify />}
            </Stack>
          } />
          {/* <Route path="/downloader" element={<VideoDownloadPage />} /> */}
        </Routes>
      </ThemeProvider>
    </Router>
  );
}