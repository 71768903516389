import { IconButton } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function Contacts() {

    const theme = useTheme();

    const IconButtonStyles = {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.secondary,
      }
    };
  
    const IconStyles = {
      width: {
        xs: '10vw',
        sm: '5vw',
        lg: '2vw',
      },
      height: {
        xs: '10vw',
        sm: '5vw',
        lg: '2vw',
      },
    };
  
    const IconContainerStyles = {
      spacing: "6vw",
    };
    
  return (
    <Box className="box-3" component="section">
        <Stack className="icon-container" direction="row" sx={IconContainerStyles}>
        <IconButton aria-label="Telegram" sx={IconButtonStyles} size="large" onClick={() => {
            window.open("https://t.me/jaywalking8975", "_blank");
        }}>
            <TelegramIcon sx={IconStyles} />
        </IconButton>
        <IconButton aria-label="Github" className="icons" sx={IconButtonStyles} onClick={() => {
            window.open("https://github.com/DuckyGuy", "_blank");
        }}>
            <GitHubIcon sx={IconStyles} />
        </IconButton>
        <IconButton aria-label="Email" className="icons" sx={IconButtonStyles}>
            <EmailIcon sx={IconStyles} />
        </IconButton>
        </Stack>
    </Box>
  );
}

export default Contacts;